.content-cgu{
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 25%;
    padding-right: 25%;
    line-height: '1.6';
}

.h1-cgu {
    color: #1ed760;
}
.p-cgu {
    color: #cdcdcd;
}
.ul-cgu {
    color: #cdcdcd;
}