.div-block-58 {
  background-color: #1c1c24;
  border: 1px solid #383838;
  border-radius: 20px;
  box-shadow: 11px 3px 15px rgba(0, 0, 0, .2);
}

.grid-account {
  display: grid;
}

.edit-info-button{
  transition: transform 0.2s;
}
.edit-info-button:hover{
  transform: scale(1.2);
}

.add-credit-button{
  transition: transform 0.2s;
}
.add-credit-button:hover{
  transform: scale(1.2);
}