.input-results {
    height: 38px;
    color: #333;
    background-color: #181920;
    border: 0 solid #000;
  };

.div-results {
    border: 1px solid #4d4d4d;
    border-radius: 10px;
    padding-right: 0;
  }
.results-field {
color: #fff;
  text-align: center;
  font-size: 14px;
}
.div-list {
    width: 100%;
  color: #17181f;
  background-color: #17181f;
  border-radius: 13px
}

.grid-result2 {
  display: grid;
}
.grid-result2:nth-child(odd) {
  background-color: #17181f;
}

.grid-result2:nth-child(even) {
  background-color: #23232a;
}

.button-delete {
    max-width: 100px;
    background-color: #e82b2b;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 12px;
    transition: background-color .4s, opacity .4s, color .4s;
}
  .button-see {
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    transition: background-color .4s, opacity .4s, color .4s;
  }
  
  .button-delete:hover {
    color: #fff;
    background-color: #32343a;
  }
  .button-see:hover {
    color: #fff;
    background-color: #32343a;
  }

  .grid-result {
    display: grid;
  }
  .text-result {
    color: #999;
    text-align: left;
    font-size: 14px;
  }