.sujet4 {
    border-radius: 6px;
    box-sizing: border-box;
    color: #ffffffb3;
    line-height: 30px;
    overflow-y: auto;
    padding: 30px;
    position: relative;
  }
  .sujet4 blockquote {
    font-size: 0.875em;
  }