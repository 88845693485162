.dashboard-body{
  min-height: auto;
}
.div-test-list {
    width: 100%;
    height: 500px;
    background-color: #17181f;
    border: 1px solid #4d4d4d;
    border-radius: 14px;
    margin-top: 60px;
    padding: 20px;
  }

.scrollable {
    max-height: 500px;
    /* Set the maximum height */
    overflow-y: auto;
    overflow-x: hidden;
    /* Optional: Add a border for visibility */
    /* Optional: Add some padding */
    scrollbar-width: none;
    scrollbar-color: #888;
}

.separationbar2 {
    background-color: #4d4d4d;
    border-radius: 8px;
}

.div-block-description-test-new {
    background-color: #17181f;
    border: 1px solid #4d4d4d;
    border-radius: 15px;
    margin-top: 10px;
    padding: 10px;
    min-height: 100px;
}

.div-exercises {
    background-color: #17181f;
    border: 1px solid #4d4d4d;
    border-radius: 15px;
    margin-top: 10px;
    padding: 10px;
    min-height: 100px;
}

.send-button {
    color: #fff;
    line-height: "normal";
    cursor: pointer;
    background-color: #3898ec;
    border: 0;
    padding: 9px 15px;
    text-decoration: none;

    text-align: center;
    font-size: 14px;
    border-radius: 0 10px 10px 0;
  }

  .email-input {
    width: 100%;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.42857;
    display: block;

    height: 100%;
    min-height: 40px;
    color: #cacaca;
    background-color: #181920;
    border: 0px #000;
    border-radius: 10px 0 0 10px;
    flex: 0 auto;
    align-self: center;
    padding: 0 0 0 20px;
  }

  .name-input {
    width: 100%;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.42857;
    display: block;

    height: 100%;
    min-height: 40px;
    color: #cacaca;
    background-color: #181920;
    border: 0px #000;
    border-radius: 10px;
    flex: 0 auto;
    align-self: center;
    padding: 0 0 0 20px;
  }

  .text2-docker {
    color: #099cec;
    background-color: rgba(9, 156, 236, .5);
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }
  .text2-linux {
    color: #ffffff;
    background-color: rgba(130, 130, 130, 0.5);
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }
  .text2-kubernetes {
    color: white;
    background-color: #3872e7;
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }
  
  .text2-private {
    opacity: 1;
    color: #d42326;
    -webkit-text-stroke-color: #d35557;
    background-color: #3b2222;
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }
  
  .text2-ansible {
    color: #504d4a;
    background-color: rgba(19, 18, 17, .9);
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }

  .text2-terraform {
    color: #8e59ca;
    background-color: rgba(123, 66, 188, .5);
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }

  .text2-aws {
    color: #f79400;
    background-color: rgba(236, 134, 9, 0.5);
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }
  .text2-gitlab {
    color: #e24329;
    background-color: #fca326;
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }
  .test-title {
    font-size: 16px;
    color: #b4b4b4;
  }

  .test2-button {
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    border-radius: 4px;
}
.test2-button:hover {
    background-color: #21232d;
}
.logos {
  gap: 0.5rem;
}