@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch&display=swap');

.sidebar-exercise {
    z-index: 1;
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100%;
    background-color: #2b2b2b;
    transition: left 0.3s ease-in-out;
    font-family: 'Chakra Petch', sans-serif;
  }
  
  .sidebar-exercise.active {
    left: 0;
  }
  
  .sidebar-exercise h2 {
    margin-top: 0;
  }
  
  .sidebar-exercise p {
    margin-bottom: 0;
  }

.sidebar-exercise-toggle {
    display: block;
    position: absolute;
    top: 50%;
    right: -30px;
    border: none;
    width: 60px; /* adjust the size as needed */
    height: 60px; /* adjust the size as needed */
    background: #2b2b2b;
    color: #fff;
    transform: translateY(-50%);
    z-index: 999;
    border-radius: 50%;
  }

.side-chapters {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }
}
.side-chapters-button {
  text-align: left;
  padding-left: 15px;
  width: 100%;
  height: 8vh;
  border: none;
  color: #ffffff;
  background: #3d3c3c;
  transition: 0.2s filter ease;
  &:hover {
    filter: brightness(1.2);
  }
}
.btn-valid {
  color: #ffffff;
  background: linear-gradient(90.1deg,#1ed760 -3.22%,#00af66 84.12%);
  border-radius: 4px;
  font-family: "Roboto";
  font-size: 1rem;
  line-height: 21px;
  padding: 10px 20px;
  transition: 0.2s filter ease;
  &:hover {
    filter: brightness(1.2);
  }
}
.btn-return {
  color: #ffffff;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: "Roboto";
  line-height: 21px;
  background: #888787e5;
  transition: 0.2s filter ease;
  &:hover {
    filter: brightness(1.2);
  }
}

.btn-retry {
  color: #ffffff;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: "Roboto";
  line-height: 21px;
  background: #007bff;
  transition: 0.2s filter ease;
  &:hover {
    filter: brightness(1.2);
  }
}

.btn-pricing {
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: "Roboto";
  line-height: 21px;
  background: #007bff;
  margin-right: 10px;
  transition: 0.2s filter ease;
  &:hover {
    filter: brightness(1.2);
  }
}

.btn-home {
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: "Roboto";
  line-height: 21px;
  background: #888787e5;
  transition: 0.2s filter ease;
  &:hover {
    filter: brightness(1.2);
  }
}

.rotate {
  transition: transform 0.3s ease-in-out;
}

.rotate.active {
  transform: rotate(180deg);
}

.sujet-div{
  width: 30%;
  font-family: 'Chakra Petch', sans-serif;
}
.sujet-div-2{
  width: 50%;
  font-family: 'Chakra Petch', sans-serif;
}

.input-div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    margin-top: 10px; /* Ajoutez une marge entre le sujet et l'input */
    width: 25%;
    min-width: 140px;
    padding: 10px;
    box-sizing: border-box;
  }
}

.sujet{
  border-radius: 6px;
  background: #575757;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
  position: relative;
  //height: 100%;
  padding: 30px 30px;
  box-sizing: border-box;
  overflow-y: auto;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.7);
  h3{
    font-size: medium;
    color: rgb(255, 255, 255);
  }
  code {
    padding: 2px 4px;
    font-size: 90%;
    color:#0044ff;
    background-color: #6991ff;
    border-radius: 4px;
  }
  p{
    font-size: small;
  }
}

.vscode {
  width: 70%;
  font-family: 'Chakra Petch', sans-serif;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
}
.vscode-2 {
  width: 50%;
  font-family: 'Chakra Petch', sans-serif;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
}

.dashboard-body {
  background-color:  #111218/* Your desired background color */;
}