.rectangle {
    display: flex;
    flex-wrap: wrap;
    width: 400px;
    margin-top: 40px;
  }
  
  .box {
    width: 50%; /* 2 éléments par rangée */
    padding: 20px;
    border: 1px solid #ccc;
    text-align: center;
    transition: background-color 0.3s;
  }
  
  .box:hover {
    background-color: #f0f0f0; /* Couleur de surbrillance au survol */
  }

  .rowfree {
    display: flex;
    justify-content: center;
  }

  .logo {
    max-width: 100%; /* Ajuste la largeur maximale de l'image pour qu'elle s'adapte à la case */
    height: auto; /* Permet au logo de conserver son ratio hauteur/largeur */
  }

  .page-container {
    padding: 50px;
    display: flex;
    flex-direction: column; /* Colonne pour centrer le titre au-dessus du rectangle */
    justify-content: center; /* Centre verticalement le contenu */
    align-items: center; /* Centre horizontalement le contenu */
    height: 100%;
    max-height: 100vh;
  }
  
  .title {
    text-align: center; /* Centre le texte du titre */
    margin-bottom: 20px; /* Espace sous le titre */
  }

  .white-rectangle-page {
    padding: 50px;
    display: flex;
    flex-direction: column; /* Aligne les éléments verticalement */
    justify-content: center; /* Centre verticalement le contenu */
    align-items: center; /* Centre horizontalement le contenu */
    height: 100%;
    max-height: 100vh;
  }
  
  .white-rectangle {
    background-color: #fff; /* Couleur du rectangle blanc */
    padding: 20px;
    border: 1px solid #ccc;
    text-align: center;
  }

  .white-rectangle p {
    color: #000; /* Noir */
  }

  .btnOk {
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    font-family: "Roboto";
    line-height: 21px;
    background: linear-gradient(90.1deg, #3d3f3e -3.22%, #626865 84.12%);
    transition: 0.2s filter ease;
    &:hover {
      filter: brightness(1.2);
    }
  }

  .button-container {
    display: flex;
    justify-content: center; /* Centre horizontalement le bouton */
    margin-top: 30px;
  }