.pricing {
    width: 100%;
    height: 100vh;
    color: #333;
    padding: 50px;
  
    header {
      text-align: center;
      margin-bottom: 50px;
  
      h1 {
        font-size: 3rem;
        margin-bottom: 10px;
      }
  
      p {
        font-size: 1.2rem;
        margin-bottom: 30px;
      }
    }
  
    .pricing-plans {
      display: flex;
      justify-content: center;
      gap: 50px;
      margin-bottom: 50px;
  
      .pricing-plan {
        width: 25%;
        background-color: #f6f8fa;
        border: 1px solid #e1e4e8;
        border-radius: 3px;
        padding: 40px;
        text-align: center;
  
        h2 {
          font-size: 2.5rem;
          margin-bottom: 10px;
          color: #333;
          font-weight : bold;
        }
  
        p {
          font-size: 1.2rem;
          margin-bottom: 30px;
        }
  
        h3 {
          font-size: 2rem;
          margin-bottom: 30px;
        }
  
        ul {
          text-align: left;
          margin-bottom: 30px;
          font-size: 1.2rem;
  
          li {
            margin-bottom: 10px;
          }
        }
  
        button {
          margin-top: auto;
        }
      }
    }
  
    .pricing-cta {
      text-align: center;
  
      h2 {
        font-size: 2.5rem;
        margin-bottom: 30px;
      }
  
      button {
        margin-top: 30px;
      }
    }
  }
