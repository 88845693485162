@import url("https://fonts.googleapis.com/css2?family=Lato&family=Roboto:wght@300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

$primary: #0d0d0d;
$primary-color: #161b29;
$secondary-color: #354158;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root,
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  //background: $primary;
  background: #1c1b1b;
  color: #fff;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: Lato, sans-serif;
}

h1 {
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
}

h2 {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
}



.btn {
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: "Roboto";
  line-height: 21px;
  background: linear-gradient(90.1deg, #1ed760 -3.22%, #00af66 84.12%);
  transition: 0.2s filter ease;
  &:hover {
    filter: brightness(1.2);
  }
}

.btn-white {
  background: #fff;
  color: #000;
  &:hover {
    filter: brightness(0.8);
  }
}

.hidden-button {
  opacity: 0;
}

.btn-group {
  display: flex;
  align-items: center;
  gap: 20px;
}

.ex-header {
  margin: 25px 0;
  padding: 0px 50px;
}

.code {
  box-sizing: border-box;
  padding: 0px;
  padding-top: 0px;
  width: 100%;
  height: 100%;
}

.nav-link{
  font-size: 18px; /* Augmenter la taille du texte */
  margin-right: 20px;
}

.nav-link:last-child {/* Pour éviter que le dernier lien ait une marge droite excessive */
  margin-right: 0;
}

.navbar {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px 30px 50px;

  .left {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 15px;

    .dropdown {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 60px;

      svg {
        margin-right: 10px;
      }

      .dropdown-content {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: #333;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        margin-left: -30px;

        a {
          display: block;
          padding: 5px 0;
          text-align: center;
          color: #fff;

          &:hover {
            background-color: #444;
          }
        }
      }
    }

    img {
      width: 40px;
      height: 60px;
      object-fit: cover;
      border-radius: 60%;
    }
  }
}

.title {
  color: #fff;
  font-size: 1.8rem;
  font-family: Roboto;
}

.hero {
  background: #1f1f1f;
  width: 100%;
  height: 90vh;
  display: flex;
  padding: 0 50px;
  justify-content: space-between;
  align-items: center;
}
.hero-left {
  width: 40%;
  height: auto;
}

.hero-right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hero-svg {
  height: 100%;
  width: 100%;
  height: 80%;
}

#presentation {
  transform: scale(0.5);
}

.subtitle {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
  margin-bottom: 30px;
}

/**
**/
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95%;
  gap: 50px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  gap: 50px;
}

.exercise {
  padding: 0px 50px;
}

.exercise-content {
  height: 60vh;
}

.exercise-btn {
  display: flex;
  margin-top: 20px;
  float: right;
  gap: 10px;
}

.terminal {
  height: 30%;
}

.subject,
.ide {
  width: 50%;
  background: #575757;
  font-family: 'Chakra Petch', sans-serif;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subject {
  position: relative;
  height: 100%;
  padding: 30px 30px;
  box-sizing: border-box;
  overflow-y: auto;
  line-height: 30px;

  p {
    color: rgba(255, 255, 255, 0.7);
  }
}

.ide > section:first-child {
  width: 100% !important;
  height: 100% !important;
}

.terminal {
  width: 100%;
  background: #1e1e1e;
  box-sizing: border-box;

  .terminal-content {
    width: 100%;
    height: 100%;
    padding: 30px 30px;
    box-sizing: border-box;
  }
}

#Bookish-stroke path {
  stroke-dasharray: 700;
  stroke-dashoffset: 700;
}

#Bookish-bg {
  opacity: 0;
}

.about {
  padding: 100px 70px;
  width: 100%;
  background: #faf8ff;
  color: #1f1f1f;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.about .left,
.about .right {
  width: 45%;
}

.about .right img {
  width: 100%;
}

.about h3 {
  margin-bottom: 30px;
  font-size: 1.8rem;
}
.about p {
  font-size: 1rem;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.4);
}

.about .skills {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  margin-top: 30px;
}

.about .skills svg {
  font-size: 3rem;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.section2 {
  width: 100%;
  height: 100vh;
  background: #343341;
  color: #fff;
  padding: 50px;
}

.section2 .cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.section2 .card {
  text-align: center;
  /* border-radius: 15px; */
  padding: 50px 20px;
  transition: 0.2s;
  height: 250px;
}

.section2 .card:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.section2 .card svg {
  font-size: 2rem;
  color: #1ed760;
  transition: 0.2s;
}
.section2 .card:hover svg {
  font-size: 2.2rem;
}

.section2 .card h4 {
  margin: 30px 0 20px 0;
  font-size: 1.2rem;
}

.section2 .card p {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}

footer {
  color: #fff;
  width: 100%;
  padding: 50px 250px;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 50px;
}

.footer-social div {
  display: flex;
  align-items: center;
  gap: 30px;
}
.footer-social a svg {
  font-size: 2rem;
}

footer h5 {
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.footer-links div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
footer p {
  margin-top: 30px;
  text-align: center;
}

.signin,
.signup {
  width: 100%;
  height: 100vh;
  color: #fff;
  padding: 50px;

  h3 {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 30px;
  }

  p {
    font-size: 0.8rem;
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;

    a {
      background-color: rgba(255, 255, 255, 0.8);
      font-size: 0.8rem;
    }
  }

  form {
    width: 30%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;

    input {
      padding: 10px 20px;
    }
  }
}
.container {
  display: flex;
  height: 100vh;
  width: 100%;
  .sidebar {
    position: fixed;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.0144) 114.26%
    );
    .items {
      display: flex;
      width: 100%;
      flex-direction: column;

      margin-top: 50px;
      justify-content: center;
      align-items: center;

      .item {
        width: 100%;
        padding: 30px 15px;
        display: flex;
        gap: 15px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:hover {
          background: #fff;
          color: #000;
        }

        svg {
          margin-right: 0 !important;
        }
        a {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }

      .item.selected {
        background: linear-gradient(90deg, #1ed760 0%, #00af66 100%);
        color: #fff;
      }
    }
  }

  .content {
    width: 100%;
    padding-left: 130px;
  }
}
/*
* RESPONSIVE
*/
@media screen and (max-width: 1250px) {
  .hero {
    justify-content: center;
    text-align: center;
  }

  .hero-left {
    width: 100%;
  }
  .hero-right {
    display: none;
  }

  .hero-left h2 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .about {
    height: auto;
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
  }
  .about .left,
  .about .right {
    width: 100%;
  }

  .about h3 {
    font-size: 1.5rem;
    text-align: center;
  }

  .about p {
    font-size: 0.8rem;
  }

  .section2 {
    height: auto;
  }
  .section2 .cards {
    grid-template-columns: 1fr 1fr;
  }

  footer {
    padding: 50px;
  }

  footer p,
  footer a {
    font-size: 0.8rem;
  }

  .exercise-content {
    flex-direction: column;
    height: auto;
    .subject,
    .ide {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 730px) {
  .hero-left h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  .navbar .right {
    display: none;
  }

  .about h3 {
    font-size: 1.2rem;
  }
  .about .skills svg {
    font-size: 2.5rem;
  }
  .section2 .cards {
    grid-template-columns: 70%;
    justify-content: center;
  }
}

@media screen and (max-width: 550px) {
  .section2 .cards {
    grid-template-columns: 100%;
  }
  .section2 .cards .card {
    height: auto;
  }

  .footer-top {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  .footer-top h5 {
    /* text-align: center; */

    font-size: 1rem;
  }

  .footer-social a svg {
    font-size: 1.5rem;
  }
}

/*
* ANIMATION
*/

#code,
#subject,
#terminal {
  transform: translateX(2000px);
}

#subject-title,
#subject-text,
#code-text,
#run-button,
#valid-test,
#fail-test,
#fail-test2 {
  opacity: 0;
}

#tests {
  overflow: hidden;
}
