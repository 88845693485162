.unauthorized {
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    align-items: center;
    padding: 50px;
  
    .message {
      text-align: center;
      margin-bottom: 30px;
  
      h3 {
        font-size: 1.8rem;
      }
  
      p {
        font-size: 1.2rem;
        margin-bottom: 10px;
      }
    }
  
    .button-container {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }