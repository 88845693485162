.sidebar-exercise {
    z-index: 1;
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100%;
    background-color: #2b2b2b;
    transition: left 0.3s ease-in-out;
    font-family: 'Chakra Petch', sans-serif;
  }
  
  .sidebar-exercise.active {
    left: 0;
  }
  
  .sidebar-exercise h2 {
    margin-top: 0;
  }
  
  .sidebar-exercise p {
    margin-bottom: 0;
  }

.sidebar-exercise-toggle {
    display: block;
    position: absolute;
    top: 50%;
    right: -20px;
    border: none;
    width: 40px; /* adjust the size as needed */
    height: 40px; /* adjust the size as needed */
    background:#fff;
    color:#2b2b2b;
    transform: translateY(-50%);
    z-index: 999;
    border-radius: 50%;
  }

.side-chapters {
  overflow: hidden;
  display: flex;
  flex-direction: column; // Mettez en colonne les éléments internes
  gap: 8px; // Ajoutez un espace entre les éléments internes (facultatif)
  width: 100%;
  height: 100%;

}

.side-chapter-item {
    color: #979191;
    display: flex;
    align-items: center; // Alignez les éléments verticalement
    padding-left: 5%;
    margin-left: 5%;
    margin-right: 5%;
    max-height: 40px;
    transition: 0.2s filter ease;
    &:hover {
        filter: brightness(1.2);
    }
    &.active {
      color: #1ed760;
    }
}

.side-chapters-button {
    background: transparent;
    text-align: left;
    padding-left: 15px;
    margin-left: 5%;
    width: 100%;
    max-height: 40px;
    height: 8vh;
    border: none;
    color: #979191;
    position: relative; /* Ajoutez cette ligne pour définir le contexte de positionnement */
  
    &.active {
        color: #1ed760;
        &::after {
            content: "";
            display: block;
            width: 5px; /* Largeur de la barre à droite du bouton */
            height: 100%;
            background-color: #1ed760; /* Couleur verte */
            border-radius: 2px;
            position: absolute;
            top: 0;
            right: 0; /* Positionnez la barre à droite */
          }
    }
  }
  

  .App {
    text-align: center;
  }
  
  .header {
    background-color: #333;
    color: #fff;
    padding: 20px;
  }
  
  .search-bar {
    margin: 20px;
  }
  
  .search-bar input {
    padding: 10px;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  
  .search-bar button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .search-result-box {
    margin: auto;
    width: 100%;
    min-height: 200px;
    max-width: 400px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
  }
  
  .search-result {
    margin: 20px;
  }

  .gray-box {
    background-color: #f2f2f2; /* Gris clair */
    margin: auto;
    max-width: 50%;
    width: 100%;
    padding: 20px;
    margin-top: 30px; /* Ajouter un espace entre les rectangles */
    margin-bottom: 10px;
  }
  
  .buttons {
    display: flex;
    justify-content: center; /* Centrer les boutons horizontalement */
    gap: 10px; /* Ajouter un espace entre les boutons */
  }
  
  .buttons button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  .email-box{
    margin: auto;
    padding: 10px 20px;
    margin-top: 50px;
  }

  .email-box button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-right: 30px;
  }

  .email-box input{
    min-width: 400px;
  }