.courses-list {
  .courses {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 50px;

    .course {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .course-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        img {
          width: 150px;
          height: 150px;
          object-fit: cover;
        }

        .course-details {
          text-align: center;
          width: 100%;

          h4 {
            margin-bottom: 10px;
          }

          p {
            margin: 0;
          }
        }
      }

      .course-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
      }
    }
  }
}
.courses-list,
.recommandations-list {
  padding: 50px;
  width: 100%;

  h3 {
    margin-bottom: 30px;
    font-size: 1.8rem;
    font-weight: 300;
  }
}

.recommandations-list {
  .recommandations {
    display: flex;
    gap: 100px;
    .recommandation {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .recommandation-image {
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .recommandation-details {
        width: 100%;
        text-align: center;
        h4 {
          font-size: 1.2rem;
          font-weight: 500;
          margin-bottom: 10px;
        }
        p {
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }
  }
}
