.p-devops {
    color: #cdcdcd;
}

.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    max-width: 800px;
    margin: 16px auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .card-content {
    display: flex;
    justify-content: space-between;
  }
  
  .card-info {
    flex: 1;
    margin-right: 16px;
  }
  
  .card-info h2 {
    margin: 0;
    font-size: 1.5rem;
  }
  .card-info p {
    color: #cdcdcd;
    margin: 8px 0;
  }
  
  .card-technologies {
    color: #cdcdcd;
    flex: 1;
    max-width: 300px;
  }
  
  .card-technologies ul {
    list-style-type: none;
    padding: 0;
  }
  
  .card-technologies ul li {
    background: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 4px 0;
    padding: 4px 8px;
  }