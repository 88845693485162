.con-button {
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    font-family: "Roboto";
    line-height: 21px;
    background: linear-gradient(90.1deg, #1ed760 -3.22%, #00af66 84.12%);
    transition: 0.2s filter ease;
    display: flex; /* Add flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    &:hover {
        filter: brightness(1.2);
    }
}

.markdown-sujet {
    background-color: darkgray;
}

.signin-link {
    color:#1ed760;
}