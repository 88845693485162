.text3-docker {
    color: #099cec;
    background-color: rgba(9, 156, 236, .5);
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }
  .text3-linux {
    color: #ffffff;
    background-color: rgba(130, 130, 130, 0.5);
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }
  .text3-kubernetes {
    color: white;
    background-color: #3872e7;
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }
  
  .text3-private {
    opacity: 1;
    color: #d42326;
    -webkit-text-stroke-color: #d35557;
    background-color: #3b2222;
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }
  
  .text3-ansible {
    color: #504d4a;
    background-color: rgba(19, 18, 17, .9);
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }

  .text3-terraform {
    color: #8e59ca;
    background-color: rgba(123, 66, 188, .5);
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }

  .text3-aws {
    color: #f79400;
    background-color: rgba(236, 134, 9, 0.5);
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }
  .text3-gitlab {
    color: #e24329;
    background-color: #fca326;
    border-radius: 20px;
    align-self: center;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 700;
  }