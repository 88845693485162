.rounded-video {
  border-radius: 10px; /* Adjust this value as needed */
}
.div-block {
  background-color: #333131;
}
.container-33home2{
  background-color: #333131;
  width: 100%;
  max-width: none;
  margin-left: 0;
  margin-right: 0;
  padding-top: 200px;
  padding-bottom: 50px; 
}
.heading-jumbohome2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 700px) {
  .heading-jumbohome2 {
    font-size: 35px; /* Revert to original size on larger screens */
  }
}

.intro-text{
  width: 90%;
}
.text-span2 {
  color: #00af66;
  font-size: 60px;
  font-weight: 600;
  position: relative;
  margin-left: 8px;
  //display: inline-block;
  width: 350px; // Adjust this width based on your longest text
  height: 1.2em; // Adjust based on your font size
  //vertical-align: middle;
}

.text-switcher {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.text-switch-item {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  transition: top 0.5s ease-in-out;
  display: flex;
  align-items: center;
}

.heading-jumbohome2 {
  max-width: 100%;
  text-transform: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 50px;
  font-weight: 400;
  line-height: 80px;
  display: block;
}

.text-switch-item.active {
  top: 0;
}

.text-switch-item.active ~ .text-switch-item {
  top: -100%;
}

.containerhome2{
    width: 100%;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
}

.hide-on-small-screens {
  display: none;
}

@media (min-width: 991px) {
  .hide-on-small-screens {
    display: inline-block;
  }
}

.show-on-small-screens {
  display: none;
}

@media (max-width: 991px) {
  .show-on-small-screens {
    display: inline-block;
    text-align: right;
  }
}

.menu-a {
  color: white;
  font-size: medium;
}

.title-technologies{
  font-size: 40px;
}

.text-block-2home2 {
  color: var(--white);
  margin-top: 220px;
  padding-bottom: 70px;
  padding-left: 220px;
  font-size: 35px;
  font-weight: 700;
  line-height: 40px;
}
@media (max-width: 1100px) {
  .text-block-2home2 {
    padding-left: 150px;
  }
}

@media (max-width: 700px) {
  .text-span2 {
    font-size: 45px; /* Revert to original size on larger screens */
  }
  .video {
    min-width: 450px; /* Revert to original size on larger screens */
  }
  .text-block-2home2 {
    padding-left: 50px;
  }
}
@media (max-width: 480px) {
  .text-span2 {
    font-size: 35px; /* Revert to original size on larger screens */
  }
}