.sujet2 {
  background-color: #373737;
  box-shadow: 8px 8px 16px #00000080;
  //box-sizing: border-box;
  color: #ffffffb3;
  line-height: 30px;
  overflow-y: auto;
  padding: 30px;
  height: 100%;
  //position: relative;
}
.sujet3 {
  background-color: #17181f;
  border-radius: 6px;
  box-shadow: 8px 8px 16px #00000080;
  box-sizing: border-box;
  color: #ffffffb3;
  line-height: 30px;
  overflow-y: auto;
  padding: 30px;
  position: relative;
}
.h3{
  font-size: 25px;;
  margin-bottom: 30px;
  margin-top: 30px;
}
.p{
  margin-top: 0px;
}

.progress-bar {
    font-family: 'Chakra Petch', sans-serif;
    display: flex;
    box-sizing: border-box;
    width: 20%;
    gap: 50px;
  }

.exercise-btn2 {
  display: flex;
  gap: 10px;
}

.blockquote {
  font-size: 16px;
}